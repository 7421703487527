<template>
    <div class="auth-page">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 p-4 mx-auto bg-light rounded">
                    <div class="text-center mb-4">
                        <img :src="mediaBaseURL + 'assets/images/logo.png'" alt="" class="auth-logo">
                    </div>
                    
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(submitForm)" class="mt-3">

                            <ValidationProvider tag="div" class="form-group" name="New Password" rules="required|min:5|max:20" v-slot="{ errors }" vid="Password">
                                <input type="password" :class="{'form-control':true,'is-invalid':errors[0]}" placeholder="New password" v-model="resetPassword.password">
                                <div class="invalid-feedback" v-show="errors">{{ errors[0] }}</div>
                            </ValidationProvider>

                            <ValidationProvider tag="div" class="form-group" name="New password confirmation" rules="required|min:5|max:20|confirmed:Password" v-slot="{ errors }" vid="password">
                                <input type="password" :class="{'form-control':true,'is-invalid':errors[0]}" placeholder="Re type new password" v-model="retypePassword">
                                <div class="invalid-feedback" v-show="errors">{{ errors[0] }}</div>
                            </ValidationProvider>

                        

                            <div class="form-group form-primary">
                                <button class="btn btn-primary btn-block" :disabled="submitProcessing">
                                    <span v-if="!submitProcessing">Change Password</span>        
                                    <span v-else>Please Wait...</span>        
                                </button>

                            </div>

                            <div class="form-group row mt-5">
                                <div class="col-12 text-center">
                                    <router-link :to="{name:'Register'}" class="text-right f-w-600">Still haven't signed up?</router-link>
                                </div>
                                <div class="col-12 text-center">
                                    OR
                                </div>
                                <div class="col-12 text-center">
                                    <router-link :to="{name:'Login'}" class="text-right f-w-600">Already have an account?</router-link>
                                </div>
                            </div>

                        </form>
                    </ValidationObserver>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../api/http";
import Message from "../../helper/Message";

export default {
  name: "ResetPassword",
    data() {
        return {
            mediaBaseURL : process.env.VUE_APP_MEDIA_BASE_URL,
            submitProcessing: false,
            retypePassword: null,
            resetPassword: {
                email: null,
                password: null,
                token: null
            }
        };
    },
    methods: {
        submitForm() {
            this.submitProcessing = true
            this.resetPassword.email = this.$route.query.email
            this.resetPassword.token = this.$route.query.token
            API.post('reset-password', this.resetPassword).then((response) => {
                Message.show(response.message);
                this.submitProcessing = false
                if(response.success){
                    this.$router.push({ name: 'Login'});
                }
            })
            .catch( () => {
                this.submitProcessing = false
            });
        }
    }
};
</script>
