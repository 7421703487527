<template>
  <div class="row mt-5">
    <div class="col-12 offset-md-3 col-md-6 offset-md-4 col-lg-4">
      <div class="mx-auto my-5 text-center">
        <a href="#" v-if="publicURL">
          <img :src="publicURL + 'images/blastchat.png'" alt="" class="login-logo w-75">
        </a>
      </div>
      <div class="login-form">
        
        <Form @submit="authenticate" :validation-schema="loginValidationSchema" v-slot="{ errors }">
          
            <div class="form-group" name="Email Address" rules="required|email">
              <label class="col-12 pl-0">Email Address</label>
              <Field type="text" name="email" :class="{'form-control':true,'is-invalid': errors.email}" 
                placeholder="Your Email Address" v-model="login.email" />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group" name="Password" rules="required|min:5|max:20" vid="password">
              <label class="col-12 pl-0">Password</label>
              <input type="password" name="password" :class="{'form-control':true,'is-invalid': errors.password}" 
                placeholder="Password" v-model="login.password">
              <div class="invalid-feedback">{{ errors.password }}</div>
            </div>

            <!-- <div class="form-group row mt-2">
              <div class="col-6">
                <label>
                  <input type="checkbox" value="">
                  <span class="text-inverse ml-1">Remember me</span>
                </label>
              </div>
              <div class="col-6 text-right">
                <router-link :to="{name:'ForgotPassword'}" class="text-right f-w-600"> Forgot Password?</router-link>
              </div>
            </div> -->

            <div class="form-group text-center">
              <button class="btn btn-success">Sign in</button>
            </div>

        </Form>

      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import API from "../../api/http";
import { useToast } from "vue-toastification";

export default {
  name: "Login",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      publicURL : process.env.VUE_APP_BASE_URL,
      login: {
        email: null,
        password: null
      },
      loginValidationSchema: null
    };
  },
  methods: {
    authenticate() {
      const toast = useToast();
      toast;
      API.authenticate(this.login).then((user) => {
        if(user.role == 'admin'){
          this.$router.push({ name: 'BlastList' });
        }
      });
    }
  }
};
</script>
