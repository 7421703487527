<template>
    <div class="auth-page">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 p-4 mx-auto bg-light rounded">
                    <div class="text-center mb-4">
                        <img :src="mediaBaseURL + 'assets/images/logo.png'" alt="" class="auth-logo">
                    </div>
                    <h3 class="text-center font-weight-normal pb-3">Forgot your password?</h3>
                    <p>Type in your email, and we'll send you a link to set up a new one.</p>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(submitForm)" class="mt-3">

                            <ValidationProvider tag="div" class="form-group" name="Email Address" rules="required|email" v-slot="{ errors }">
                                <input type="text" :class="{'form-control':true,'is-invalid':errors[0]}" placeholder="Your Email Address" v-model="email">
                                <div class="invalid-feedback" v-show="errors">{{ errors[0] }}</div>
                            </ValidationProvider>

                            <div class="form-group form-primary">
                                <button class="btn btn-primary btn-block" :disabled="submitProcessing">
                                    <span v-if="!submitProcessing">Submit</span>        
                                    <span v-else>Please Wait...</span>        
                                </button>
                            </div>

                            <div class="form-group row mt-5">
                                <div class="col-12 text-center">
                                <router-link :to="{name:'Login'}" class="text-right f-w-600">Login</router-link>
                                </div>
                            </div>

                        </form>
                    </ValidationObserver>
                </div>
                
            </div>
        </div>
  </div>
</template>

<script>

import API from "../../api/http";
import Message from "../../helper/Message";

export default {
    name: 'ForgotPassword',
    data() {
        return {
            mediaBaseURL : process.env.VUE_APP_MEDIA_BASE_URL,
            submitProcessing: false,
            email: null
        };
    },
    methods: {
        submitForm() {
            this.submitProcessing = true
            API.post('forgot-password',{email: this.email}).then((response) => {
                Message.show(response.message);
                this.submitProcessing = false
            }).catch( () => {
                this.submitProcessing = false
            });
        }
    }
}
</script>
